import { Injectable } from '@angular/core';
import { Deal } from '@app/models/menu/deal';
import { DealLine } from '@app/models/menu/deal-line';
import { DealLineType } from '@app/models/menu/deal-line-type.enum';
import { OrderOccasion } from '@app/models/order-occasion';
import { MenuService } from '@app/api/menu.service';
import { VariantList } from '@app/models/menu/variant-list';
import { IDictionary } from '@app/models/IDictionary';
import { ProductVariant } from '@app/models/menu/product-variant';
import { ListedProductVariant } from '@app/models/menu/listed-product-variant';
import { MenuVariantHelperService } from '../menu-variant-helper/menu-variant-helper.service';
import { Menu } from '@app/models/menu/menu';
import { takeUntil } from 'rxjs';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Variant } from '@app/models/menu/variant';

@Injectable({
  providedIn: 'root'
})
export class MenuDealHelperService extends AndroWebCoreComponent {
  private _variantListCache: IDictionary<ProductVariant[]> = {};

  constructor(
    private _menuService: MenuService,
    private _menuVariantHelperService: MenuVariantHelperService
  ) {
    super();
    this._menuService.menu$
        .pipe(takeUntil(this.destroy$))
        .subscribe((menu: Menu) => {
          if (!menu) {
            return;
          }

          this._variantListCache = {};
        });
  }

  /**
   * Get a price summary string for a deal.
   * @param {Deal} deal - The deal for which the price summary is generated.
   * @param {OrderOccasion} occasion - The occasion for which the deal is being calculated.
   * @returns {string} A string representing the price summary for the deal.
   */
  public getPriceSummaryForDeal(deal: Deal, occasion: OrderOccasion): string {
    return `${this.getPricePrefixForDeal(deal)}${this.getMinPriceForDeal(deal, occasion).toFixed(2)}`;
  }

  /**
   * returns the price prefix for a deal
   * @param deal
   */
  public getPricePrefixForDeal(deal: Deal): string {
    return deal.Lines.every((x: DealLine) => x.Type === DealLineType.Fixed) ? '£' : 'from £';
  }

  /**
   * Calculate the minimum price for a deal for a specific occasion and menu.
   * @param {Deal} deal - The deal for which the minimum price is calculated.
   * @param {OrderOccasion} occasion - The occasion for which the deal is being calculated.
   * @returns {number} Min price for the deal.
   */
  public getMinPriceForDeal(deal: Deal, occasion: OrderOccasion): number {
    const totalPrice: number = deal.Lines
        .map((line: DealLine) => this.calculateLinePrice(line, occasion))
        .reduce((sum: number, linePrice: number) => sum + linePrice, 0);

    // Ensures non-negative total
    return Math.max(totalPrice, 0);
  }

  /**
   * Calculate the price for a specific deal line.
   * @param {DealLine} line - The deal line for which the price is calculated.
   * @param {OrderOccasion} occasion - The occasion for which the deal line is being calculated.
   * @returns {number} The calculated price for the deal line.
   */
  private calculateLinePrice(line: DealLine, occasion: OrderOccasion): number {
    if (!this.getMenuValue().VariantLists.some((vl: VariantList) => vl.Id === line.VariantList)) {
      return 0;
    }

    if (line.Type === DealLineType.Fixed) {
      return line.Value;
    }

    const minItemPrice: number = this.getMinimumPriceFromVariantList(line.VariantList, occasion);

    // Adjust price based on the deal line type
    switch (line.Type) {
      case DealLineType.Percent:
        return minItemPrice * (line.Value / 100);
      case DealLineType.Discount:
        return minItemPrice - line.Value;
    }
  }

  /**
   * Get the minimum price from a variant list.
   * @param {string} variantListId - The ID of the variant list.
   * @param {OrderOccasion} occasion - The occasion for which the minimum price is being calculated.
   * @returns {number} The minimum price from the variant list.
   */
  private getMinimumPriceFromVariantList(variantListId: string, occasion: OrderOccasion): number {
    let minPrice: number = Number.MAX_VALUE;

    for (const product of this.getProductVariantsFromList(variantListId)) {
      const price: number = this._menuVariantHelperService.getMinPriceForVariants(product.variants, occasion);

      if (!price && price !== 0) {
        continue;
      }

      minPrice = Math.min(minPrice, price);
    }

    return minPrice;
  }

  /**
   * Retrieves a list of products and their associated variants from a given list identifier.
   *
   * @param list - The identifier of the list from which to retrieve product variants.
   * @returns An array of objects, each containing a product and its associated variants.
   */
  public getProductVariantsFromList(list: string): ListedProductVariant[] {
    const products: ListedProductVariant[] = [];

    this.getVariantsFromListId(list)
        .forEach((productVariant: ProductVariant) => {
          const { Product: product, Variant: variant } = productVariant;

          const group: ListedProductVariant = products.find((p: ListedProductVariant) => p.product.Id === product.Id);

          if (group) {
            group.variants.push(variant);
          } else {
            products.push({ product, variants: [variant] });
          }
        });

    this.sortVariantsByProductOrder(products);

    return products;
  }

  /**
   * Sorts the variants within each product group by their order in the product's variants array.
   * @param products - The array of products with associated variants to be sorted.
   */
  private sortVariantsByProductOrder(products: ListedProductVariant[]): void {
    products.forEach(({ product, variants }) => {
      // Create a mapping of OptionsPath to index from the Product.variants array
      const orderMap = new Map(
          product.Variants.map((variant, index) => [variant.OptionsPath.join(','), index])
      );

      // Sort variants based on their index in the product's variants array
      variants.sort((a: Variant, b: Variant) => {
        const aIndex: number = orderMap.get(a.OptionsPath.join(',')) ?? Infinity;
        const bIndex: number = orderMap.get(b.OptionsPath.join(',')) ?? Infinity;
        return aIndex - bIndex;
      });
    });
  }

  /**
   * returns an array of product variants that match the given list id
   * @param menu
   * @param listId
   */
  private getVariantsFromListId(listId: string): ProductVariant[] {
    if (!this._variantListCache[listId]) {
      this._variantListCache[listId] = this.getMenuValue().VariantLists
          .find((x: VariantList) => x.Id === listId)
          .Variants
          .map((x: string) => this.getProductVariantsById()[x])
          .filter((x: ProductVariant) => x);
    }

    return this._variantListCache[listId] ?? [];
  }

  private getMenuValue: () => Menu = () => this._menuService.menuValue;
  private getProductVariantsById: () => IDictionary<ProductVariant> = () => this._menuService.productVariantsById;
}
